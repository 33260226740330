// Colors

.color-primary {
	color: $primary;
}

.color-secondary {
	color: $secondary;
}

.color-tertiary {
	color: $tertiary;
}

.color-quaternary {
	color: $quaternary;
}

.color-quinary {
	color: $quinary;
}

.color-senary {
	color: $senary;
}

// Colors state

.color-primary-hover:hover,
.color-primary-hover:focus,
.color-primary-hover.active {
	color: darken($primary, 25%);
}

.color-secondary-hover:hover,
.color-secondary-hover:focus,
.color-secondary-hover.active {
	color: darken($secondary, 25%);
}

.color-tertiary-hover:hover,
.color-tertiary-hover:focus,
.color-tertiary-hover.active {
	color: darken($tertiary, 25%);
}

.color-quaternary-hover:hover,
.color-quaternary-hover:focus,
.color-quaternary-hover.active,
.color-quaternary-hover {
	color: darken($quaternary, 25%);
}

.color-quinary-hover:hover,
.color-quinary-hover:focus,
.color-quinary-hover.active {
	color: darken($quinary, 25%);
}

.color-senary-hover:hover,
.color-senary-hover:focus,
.color-senary-hover.active {
	color: darken($senary, 25%);
}

// Spacing

// ...
